<template>
  <BaseMenu
    :items="dropdownItems"
    position="right"
    @click="
      droppedDown = !droppedDown;
      $emit('click');
    "
    @action="actionHandler"
  >
    <template #icon>
      <UserIcon class="h-4 w-4" />
      <ChevronDownIcon
        :class="{ 'rotate-180': droppedDown }"
        class="h-4 w-4 transform text-gray-400 duration-100 ease-in-out"
      />
    </template>
  </BaseMenu>
  <ModalFeedback v-model="showFeedbackModal" />
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { UserIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';
import ModalFeedback from '@/components/modal/ModalFeedback.vue';

const store = useStore();
function actionHandler(action) {
  if (action == 'feedback') {
    showFeedbackModal.value = true;
  } else if (action === 'sign_out') {
    localStorage.removeItem('selectedChats');
    localStorage.removeItem('closedChats');
    localStorage.removeItem('chatSidebarOpened');
    window.location.replace('/users/sign_out');
  }
}

const showFeedbackModal = ref(false);
const droppedDown = ref(false);

const items = ref([
  { href: '/settings', label: 'Settings' },
  { href: '/sidekiq', label: 'Sidekiq', permission: 'sidekiq' },
  { href: '/admin', label: 'Admin', permission: 'admin_settings' },
  // { href: '/admin/v2_coins', label: 'Admin V2', permission: 'admin_settings' },
  { action: 'feedback', label: 'Send Feedback' },
  { href: '/onchain_addresses', label: 'On-Chain Addresses' },
  { action: 'sign_out', label: 'Sign Out' }
]);

const dropdownItems = computed(() =>
  items.value.filter(item => !item.permission || store.getters.permissions.includes(item.permission))
);
</script>
